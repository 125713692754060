/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      
	        //custom function to open menu dropdowns header
			$('.header__ul > li > a').on('click', function(e) {
				if( $(this).parent().hasClass('menu-item-has-children') ) {
					e.preventDefault();
					var hasClass =  $(this).parent().hasClass('open');
					$('.dropdown').removeClass('open');
					if( hasClass === true ) {
						$(this).parent().removeClass('open'); 
					} else {
						$(this).parent().addClass('open'); 
					}
				}
			});
			//custom function to open menu dropdowns slideins
			$('.ql__ul > li > a').on('click', function(e) {
				e.preventDefault();
				console.log(124);
				$(this).parent().toggleClass('open'); 
			});
			
	      	//init slick slider in header				
			$('.js-slick-header-slider').slick({
			    dots: true,
			    arrows: true,
			    infinite: true,
			    autoplay: true,
			    autoplaySpeed: 7000,
			    speed: 300,
				fade: false
			});
			
			//init slick slider on detailpage
			$('.js-slick-slider').slick({
			    dots: false,
			    arrows: true,
			    infinite: true,
			    autoplay: true,
			    autoplaySpeed: 5000,
			    speed: 300,
				fade: false
			});
			
			//init slick projects slider
			$('.js-slick-projects-slider').slick({
			    dots: false,
			    arrows: true,
			    infinite: true,
			    autoplay: false,
			    speed: 1,
				fade: true
			});
			
			//init slick slider on projects overveiw page
			$('.js-slick-overview-projects-slider').slick({
			    dots: false,
			    arrows: true,
			    infinite: true,
			    autoplay: false,
			    autoplaySpeed: 10000,
			    speed: 300,
				fade: false
			});
			
			//initialise slick projects slider on display toggle
			$('body:not(.home) #projects-slider').on( "click", function() {
				if( $('.projects-ul .dropdown-menu').css('display') === 'none') {
					$('.projects-ul .dropdown-menu').css('display', 'block');
					$('.projects-ul > li').removeClass('open');
				} else {
					$('.projects-ul .dropdown-menu').css('display', 'none');
					$('.projects-ul > li').addClass('open');
				}
		
			    $('.projects-ul .dropdown-menu').get(0).slick.setPosition();
			    
			    //set projects in project slider to same height
				$('.projects__slider .slick-track').each(function(){
					var highestBox = 0,
						elements = jQuery(this).find('.slider__text-row');
			        elements.each( function() {
			            if($(this).outerHeight() > highestBox){
			                highestBox = $(this).outerHeight();
			            }
			        });
					elements.outerHeight(highestBox);
				});
			});
			
			//set teasers to same height
			if($(window).width() >= 767) {
				$('.cat-section').each(function(){
					var highestBox = 0,
						elements = jQuery(this).find('.teaser__title');
						
			        elements.each(function(){
			            if($(this).outerHeight() > highestBox){
			                highestBox = $(this).outerHeight();
			            }
			        });
					elements.outerHeight(highestBox);
				});
			}
			
			//set taxonomy previews on overview page to same height
			if($(window).width() >= 767) {
				$('.cat-section').each(function(){
					var highestBox = 0,
						elements = jQuery(this).find('.category');
						
			        elements.each(function(){
			            if($(this).outerHeight() > highestBox){
			                highestBox = $(this).outerHeight();
			            }
			        });
					elements.outerHeight(highestBox+70);
				});
			}
			
			//set projects in project slider on home page to same height
			$('.home .projects__slider .slick-track').each(function(){
				var highestBox = 0,
					elements = jQuery(this).find('.slider__text-row');
					
		        elements.each(function(){
		            if($(this).outerHeight() > highestBox){
		                highestBox = $(this).outerHeight();
		            }
		        });
				elements.outerHeight(highestBox);
			});
			
			//set team members to same height
			if($(window).width() > 767) {
				$('.tab-content .team__tab').each(function(){
					var highestBox = 0,
						elements = jQuery(this).find('.person__box');
						
			        elements.each(function(){
			            if($(this).outerHeight() > highestBox){
			                highestBox = $(this).outerHeight();
			            }
			        });
					elements.outerHeight(highestBox);
				});
			}
			
			//enable link to tab for tabpages
		    var url = document.location.toString();
		    if (url.match('#')) {
		        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
		    }
		
		    // Change hash for page-reload
		    $('.nav-tabs a').on('shown.bs.tab', function (e) {
		        window.location.hash = $(this).data('target');
		        $(window).scrollTop(520);
		    });
		
		    $('.page-template-template-tabs').animate({
				scrollTop: $('.nav-tabs a[href="#' + url.split('#')[1] + '"]') + 220
			} , 700);
			
			
			
			//datepicker forms
			if( typeof gform !== 'undefined' ) {
				gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
					optionsObj.minDate = 0;
					return optionsObj;
				});
			}
				
			/* German initialisation for the jQuery UI date picker plugin. */
			/* Written by Milian Wolff (mail@milianw.de). */
			( function( factory ) {
				if ( typeof define === "function" && define.amd ) {
			
					// AMD. Register as an anonymous module.
					define( [ "../widgets/datepicker" ], factory );
				} else {
			
					// Browser globals
					factory( jQuery.datepicker );
				}
			}( function( datepicker ) {
			
			if( typeof datepicker !== 'undefined' ) {
				datepicker.regional.de = {
					closeText: "Schließen",
					prevText: "&#x3C; Zurück",
					nextText: "Weiter &#x3E;",
					currentText: "Heute",
					monthNames: [ "Januar","Februar","März","April","Mai","Juni",
					"Juli","August","September","Oktober","November","Dezember" ],
					monthNamesShort: [ "Januar","Februar","März","April","Mai","Juni",
					"Juli","August","September","Oktober","November","Dezember" ],
					dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
					dayNamesShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
					dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
					weekHeader: "KW",
					dateFormat: "dd.mm.yy",
					firstDay: 1,
					isRTL: false,
					showMonthAfterYear: false,
					yearSuffix: "" };
				datepicker.setDefaults( datepicker.regional.de );
				
				return datepicker.regional.de;
			}
			
			} ) );
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
	      	//set slideins on home page to same height
			if($(window).width() >= 767) {
				$('.home').each(function(){
					var highestBox = 0,
						elements = jQuery(this).find('.ql__ul .dropdown-menu');
						
			        elements.each(function(){
			            if($(this).height() > highestBox){
			                highestBox = $(this).outerHeight();
			            }
			        });
					elements.outerHeight(highestBox);
				});
			}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
